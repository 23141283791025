<template>
  <div>
    <div class="d-flex">
      <b-input
        placeholder="localizar"
        v-model="filtro"
        autocomplete="off"
        ref="txtBuscaComanda"
        autofocus
        @keydown.enter="clickSel"
      />
      <b-btn
        variant="success"
        class="w-25 ml-4"
        @click="$emit('openComanda')"
        v-if="showOpenComanda"
        >Abrir Comanda</b-btn
      >
    </div>
    <hr />
    <div :class="{ row: isOpen }">
      <div class="col-12 d-flex justify-content-between">
        <div class="d-flex">
          <b-btn
            v-if="comandaViewType == 'table'"
            variant="info"
            @click="print"
            class="mr-1"
            ><b-icon-printer /> Imprimir</b-btn
          >
          <export-excel :data="relatorioExcel" >
            <b-btn variant="secondary"> <b-icon-file-excel /> Exportar </b-btn>
          </export-excel>
        </div>
        <div>
          <b-btn-group>
            <b-button-group
              size="sm"
              v-model="comandaViewType"
            >
              <b-button
                :variant="comandaViewType == 'card' ? 'primary' : 'none'"
                value="card"
                :active="comandaViewType == 'card'"
                @click="comandaViewType = `card`"
                ><b-icon-window-dock /> Card</b-button
              >
              <b-button
                :variant="comandaViewType == 'table' ? 'primary' : 'none'"
                value="table"
                :active="comandaViewType == 'table'"
                @click="comandaViewType = `table`"
                ><b-icon-list /> Lista
              </b-button>
            </b-button-group>
          </b-btn-group>
        </div>
      </div>
      <template v-if="comandaViewType == 'card'">
        <div
          v-for="cm in comandas"
          :key="`comanda_${cm.comanda.nome}_${Math.random()}`"
          :class="{ 'col-12 col-md-4 col-lg-3 p-2 ': isOpen }"
          @click="select(cm)"
        >
          <div
            class="comanda"
            :class="{
              cmdOpen: isOpen,
              'bg-danger text-white': cm.comanda.dt_fechada,
            }"
          >
            <div class="row text-left">
              <div :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }">
                <strong
                  ><i class="fa fa-barcode mr-1"></i
                  >{{ getNome(cm.comanda, 0) }}</strong
                >
              </div>
              <div
                v-if="
                  getNome(cm.comanda, 1) && getNome(cm.comanda, 1).trim() != ``
                "
                :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }"
              >
                <i class="fa fa-user mr-1"></i>{{ getNome(cm.comanda, 1) }}
              </div>
              <div
                :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }"
                v-if="
                  getNome(cm.comanda, 2) && getNome(cm.comanda, 2).trim() != ``
                "
              >
                <i class="fa fa-phone mr-1"></i>{{ getNome(cm.comanda, 2) }}
              </div>
              <div :class="{ 'col-12 col-md-4': !isOpen, 'col-12': isOpen }">
                <i class="fa fa-calendar mr-1"></i
                >{{ cm.mesa.dt_aberta | moment("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
            <div v-if="cm.comanda.dt_fechada">
              <i class="fa fa-lock mr-1"></i>Comanda Fechada
            </div>
          </div>
        </div>
      </template>
      <template v-if="comandaViewType == 'table'">
        <div
          class="col-12"
          id="dvPrint"
        >
          <h5>Comandas em aberto</h5>
          <hr />
          <b-table
            :items="comandasTable"
            :fields="[
              { key: 'comanda', label: 'Comanda', sortable: true },
              {
                key: 'cliente',
                label: 'Comanda',
                sortable: true,
                class: 'text-left',
              },
              { key: 'dt_aberta', label: 'Aberta', sortable: true },
              { key: 'vl_total', label: 'Total', sortable: true },
              {
                key: 'vl_pago',
                label: 'Pago',
                sortable: true,
                class: 'text-info',
              },
              {
                key: 'vl_aberto',
                label: 'Restante',
                sortable: true,
                class: 'text-right text-danger',
              },
            ]"
            style="font-size: 10px"
            small
            hover
            selectable
            select-mode="single"
            ref="table"
            @row-selected="
              (row) => (row && row.length > 0 ? select(row[0].obj) : () => {})
            "
          >
            <template #cell(dt_aberta)="row">
              {{ row.item.dt_aberta | moment("DD/MM/YYYY HH:mm") }}
            </template>
            <template #cell(vl_total)="row">
              {{ row.item.vl_total | currency }}
            </template>
            <template #cell(vl_pago)="row">
              <span v-if="row.item.vl_pago">
                {{
                  (row.item.vl_pago ? row.item.vl_pago : "") | currency
                }} </span
              ><span v-else>--</span>
            </template>
            <template #cell(vl_aberto)="row">
              {{
                ((row.item.vl_total || 0) - (row.item.vl_pago || 0)) | currency
              }}
            </template>
          </b-table>
          <hr />
          <div
            class="d-flex justify-content-between p-2"
            style="border: 1px solid #ccc"
          >
            <div>Total</div>
            <div>
              {{
                comandasTable.reduce((acc, x) => acc + x.vl_total, 0) | currency
              }}
            </div>
          </div>
          <div
            class="d-flex justify-content-between p-2 text-info mt-1"
            style="border: 1px solid #ccc"
          >
            <div>Pago</div>
            <div>
              {{
                comandasTable.reduce((acc, x) => acc + x.vl_pago, 0) | currency
              }}
            </div>
          </div>
          <div
            class="d-flex justify-content-between p-2 text-danger mt-1"
            style="border: 1px solid #ccc"
          >
            <div>Á Receber</div>
            <div>
              {{
                comandasTable.reduce(
                  (acc, x) => acc + x.vl_total - x.vl_pago,
                  0
                ) | currency
              }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PrinterWindowHelper from "../../utils/PrinterWindowHelper";
export default {
  components: {},
  props: {
    mesas: Array,
    isOpen: Boolean,
    showOpenComanda: Boolean,
  },
  mounted() {
    this.$refs.txtBuscaComanda.focus();
    //loadviewconfig from storage
    this.comandaViewType = localStorage.getItem("comandaViewType") || "card";
  },
  data() {
    return {
      filtro: "",
      comandaViewType: "card",
    };
  },
  watch: {
    comandaViewType: function () {
      this.writeViewConfig();
    },
  },
  computed: {
    relatorioExcel: function () {
      return this.comandasTable.map((x) => ({
        Comanda: x.comanda,
        Cliente: x.cliente,
        Aberta: x.dt_aberta,
        Total: x.vl_total,
        Pago: x.vl_pago,
        Restante: (x.vl_total-x.vl_pago),
      }));
    },
    comandasTable: function () {
      return this.comandas.map((x) => ({
        comanda: this.getNome(x.comanda, 0),
        cliente: `${x.comanda.nome ? this.getNome(x.comanda, 1) : ""} - ${
          x.comanda.nome ? this.getNome(x.comanda, 2) : ""
        }`,
        dt_aberta: x.mesa.dt_aberta,
        vl_total: x.comanda.vl_total,
        vl_pago: x.comanda.vl_pago,
        vl_aberto: x.comanda.vl_aberto,
        obj: x,
      }));
    },
    comandas: function () {
      let res = [];
      for (let m of this.mesas.filter((x) => x.id)) {
        res.push(
          ...m.comandas.map((c) => ({
            mesa: m,
            comanda: c,
          }))
        );
      }
      res = res.filter(
        (x) =>
          (this.filtro &&
            this.filtro != "" &&
            x.comanda.nome
              .toLowerCase()
              .indexOf(this.filtro.toLocaleLowerCase()) >= 0) ||
          !this.filtro
      );

      res = res.reduce((ret, vl) => {
        let has = ret.find((x) => x.comanda.nome == vl.comanda.nome);
        if (!has) {
          ret.push(vl);
        }
        return ret;
      }, []);
      console.log("filtrando", this.filtro);
      return res.sort((a, b) => {
        return parseInt(a.comanda.nome.replace("Comanda nº ", "")) >
          parseInt(b.comanda.nome.replace("Comanda nº ", ""))
          ? 1
          : -1;
      });
    },
  },
  methods: {
    exportar() {},
    print() {
      PrinterWindowHelper(document.querySelector("#dvPrint").innerHTML);
    },
    writeViewConfig() {
      localStorage.setItem("comandaViewType", this.comandaViewType);
    },
    getNome(comanda, index) {
      if (comanda && comanda.nome && comanda.nome.includes("|")) {
        return comanda.nome.split("|")[index];
      } else {
        return comanda.nome || "";
      }
    },
    clickSel() {
      if (this.comandas && this.comandas.length > 0) {
        this.select(this.comandas[0]);
      }
    },
    select(comanda) {
      console.log("comanda", comanda);
      // this.$emit("mesa", comanda.mesa);
      this.$emit("comanda", comanda.comanda);

      this.$refs.table.clearSelected();
    },
  },
};
</script>

<style lang="scss" scoped>
.comanda {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-color: #ccc;
    box-shadow: 0px 0px 10px rgba(160, 160, 160, 0.3);
    background-color: #f5f5f5;
  }
}
.cmdOpen {
  min-height: 90px;
  background-color: #82d42c;
  &:hover {
    transform: scale(1.05);
  }
}
</style>
